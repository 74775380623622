@import "../../css/fonts";
@import "../../css/variables";

.profile {
    background-color: #e5e5e5;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    .grid {
        display: grid;
        grid-column-gap: 24px;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;

        @include tablet {
            display: flex;
            flex-direction: column;
        }

        .column {
            &--one {
                .extraLinks {
                    @include font-rubik(16px, var(--midnight-100), 400);
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-bottom: 12px;
                    border-radius: 8px;
                    padding: 16px;
                    background-color: var(--dust-100);
                    color: var(--midnight-100);

                    .item {
                        padding-left: 8px;

                        .icon {
                            width: 20px;
                        }
                    }
                }
            }
        }
    }
}
