@import "../../../../css/fonts";
@import "../../../../css/variables";

.profile__header {
    align-items: center;
    background-color: #e5e5e5;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;

    .profile__details {
        display: flex;
        justify-content: flex-end;

        & > *:not(:last-child) {
            margin-right: 24px;
        }

        &__info {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            &__details {
                display: flex;
                flex-direction: column;
                margin-bottom: 16px;

                &__email,
                &__phoneNumber {
                    @include font-rubik(16px, var(--midnight-100), false);
                }

                &__name {
                    @include font-rubik(36px, var(--midnight-100), 700);
                    text-transform: capitalize;
                }
            }

            &__stats {
                display: flex;
                height: 70px;
            }
        }

        @media (max-width: 900px) {
            & > * {
                margin-bottom: 24px;
            }
        }
    }

    &__credits,
    &__letters-sent {
        align-items: center;
        display: flex;
        padding-right: 24px;

        &__number {
            @include font-rubik(45px, #345168, 500);
            margin-right: 24px;
        }

        &__text {
            @include font-rubik(16px, var(--midnight-100), 300);
            display: flex;
            flex-direction: column;
        }

        &:not(:last-child) {
            border-right: 1px solid #d1d1d1;
        }

        &:last-child {
            padding-left: 24px;
        }
    }

    &__image {
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 205px;
        position: relative;
        width: 205px;

        &__upload {
            height: 100%;
            position: relative;
            width: 100%;
            transform: translateY(-100%);

            .edit-icon {
                cursor: pointer;
                height: 25px;
                position: absolute;
                right: -10px;
                top: -10px;
                width: 25px;
            }

            &__input {
                display: block;
                width: 100%;
                height: 100%;
                opacity: 0;
                visibility: hidden;
                cursor: pointer;

                &__label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    margin-bottom: 0;
                }
            }
        }
    }

    .avatar {
        @include font-rubik(32px);
        width: 100%;
        height: 100%;
    }

    @media (max-width: 900px) {
        flex-direction: column;
    }
}

.profile__navigation-detail {
    background-color: var(--dust-100);
    display: flex;
    flex-direction: column;
    padding: 26px 48px;

    h3 {
        @include font-rubik(26px, var(--midnight-100), 700);
        margin: 0px;
    }

    span {
        @include font-rubik(16px, var(--midnight-100), 300);
    }
}

.profile__cta {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;

    & > * {
        width: 100%;
    }

    :not(:last-child) {
        margin-bottom: 24px;
    }

    @media (max-width: 900px) {
        max-width: unset;
    }
}
