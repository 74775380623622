@import "../../../../css/variables";
@import "../../../../css/responsive";

.profileResources {
    @include hideScrollBar;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    margin-top: 24px;
    margin-bottom: 24px;
    overflow-x: hidden;

    .carousel {
        max-width: 100%;
        width: 100%;

        .item {
            width: 100%;
            max-width: 100%;
        }
    }
}
